
.tab-bar {
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	section.left {
		padding-left: ($column-gutter / 2)
	}
	.right-small.search {
		right: 3rem;
		text-align: center;
		a {
			color: #ffffff;
		}
	}
	.menu-icon span:after {
		box-shadow: 0 0 0 1px #ffffff, 0 7px 0 1px #ffffff, 0 14px 0 1px #ffffff;
	}
	.logo {
		img {
			margin-top: 4px;
		}
		&__text {
			margin-top: 7px;
		}
	}
}
