.off-canvas {
	&-list {
		ul.list-social {
			li {
				margin: 0;
				a {
					border: 0;
				}
			}
		}
	}
}