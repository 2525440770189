.breakingnewsstreamer {
	background: #f8a717;
	line-height: 0;
	h3 {
		margin: 0 !important;
		span {
			display: inline-block;
			&.typelabel {
				margin-right: 1rem;
			}
		}
		a {
			color: $color-dark;
			&:hover {
				color: $color-dark;
				.newsline {
					text-decoration: underline;
				}
			}
		}
	}
}

@media #{$small-only} {
	.breakingnewsstreamer {
		position: absolute;
		top: 0;
		width: 100%;
	}
}
