// loading indicator
$loading-indicator-loader-image: url(../../images/ajax-loader-bw.gif) !default;
$loading-indicator-image-margin: -8px 0 0 -8px !default;
$loading-indicator-position: relative !default;
$loading-indicator-opacity: 0.2 !default;

@mixin loading-indicator($img: $loading-indicator-loader-image, $offset: $loading-indicator-image-margin, $ps: $loading-indicator-position, $op: $loading-indicator-opacity) {

	position: unquote($ps);
	&:after {
		content: unquote($img);
		left: 50%;
		position: absolute;
		top: 50%;
		margin: $offset;
		z-index: 99999;
	}
	> * {
		opacity: $op;
		pointer-events: none;
	}
}