.crumbpath ul {
	@include crumb-container;
  	li {
    	@include crumbs;
  	}
  	.hier a {
  		color: $crumb-font-color-current;
  	}
}

// touch enabled small screens get sliding breadcrumbs
@media #{$small-only} {
	.touch .crumbpath {
		@include crumb-container;
		direction: rtl;
		display: block;
		overflow-x: auto;
		ul {
			padding: 0;
			margin: 0;
			border: none;
			background: none transparent;
			display: inline-block;
			direction: ltr;
			white-space: nowrap;
			li {
				display: inline-block;
				float: none;
			}
		}
	}
}