.list-items {
	margin-top: 2rem;
	li {
		border-bottom: 1px solid rgba(0, 0, 0, 0.05);
		padding: $column-gutter 0;
		&:first-child {
			padding-top: 0;
		}
	}
	.heading-h2 {
		text-transform: none;
	}
	article {
	}
	.thumb {
		float: left;
		margin: 8px 0 0 -140px;
		width: 120px;
	}
	footer {
		font-size: rem-calc(13);
	}
	.readmore {
		margin: -1rem 0 0 0;
	}

	figure {
		margin: 0;
		img {
			margin: 0;
		}
	}
	a figure figcaption {
		color: $body-font-color;
	}
}

.rechts_topparagraaf {
	float: left;
	margin: 8px 1rem 1rem 0;
}

.pager {
	margin-top: $column-gutter;
}

@media#{$medium-up} {
	.list-items {
		&:not(.no-images) {
			.inner-box {
				padding: 0 0 0 140px;
			}
		}
	}
}

@media#{$small-only} {
	.list-items {
		.thumb {
			position: absolute;
			top: 0;
			margin-top: 0;
			margin-left: -100px;
			width: 80px;
		}
		.intro, .readmore {
			display: none;
		}
		.inner-box {
			padding: 0;
		}
		&:not(.no-images) {
			h1 {
				margin-bottom: 0;
			}
			article {
				padding-left: 100px;
				position: relative;
				min-height: 60px;
			}
		}
		.heading-h2 {
			text-transform: none;
			font-size: 1rem;
		}
	}
}