.photoblock {
  &,
  .row {
    background-color: $black;
  }
  img {
    margin: 45px 0 0;
  }
  h1 {
    color: $white;
    margin: 2rem 0;
  }
}
