/* Galleria Frame */
.galleriaframe {
	width: 100%;
	height: 100%;
	overflow: hidden;
	background: #000000 url(../images/galleria-loader.gif) center center no-repeat;
}

.ui-dialog {
	&.photoalbum {
		background: transparent;
		border: none;
		border-radius: 0;
		padding: 0;
		
		.ui-widget-header {
			border: none;
		}
		
		.ui-dialog-titlebar {
			border-radius: 0;
			padding: 0;
			
			.ui-dialog-titlebar-close {
				&.ui-state-hover {
					border: none;
				}
			}
		}
		
		.ui-dialog-content {
			background: transparent;
			border: none;
			border-radius: 0;
			padding: 0;
		}
	}
}

ul.latest.linklist-labels {
	li {
		&:not(:hover) span.label {
			opacity: 0.5;
		}
		
		a {
			color: $color-dark;;
			
			&:hover {
				color: $primary-color;
			}
		}
	}
}

span.updatetime {
	display: block;
	margin: -12px 0 12px 0;
}

.breakingnewsstreamer {
	background: #f8a717;
	line-height: 0;
	
	h3 {
		margin: 0 !important;

		span {
			display: inline-block;
			
			&.typelabel {
				margin-right: 1rem;
			}
		}
		
		a {
			color: $color-dark;
			
			
			&:hover {
				color: $color-dark;
				
				.newsline {
					text-decoration: underline;
				}
			}
		}
	}
}

.page-home {

	.poll {
		margin-bottom: 0;
	}
}

@media #{$medium-up} {
	.page-home {
		.contentcontainer {
			padding-bottom: 10px;
			.padded {
				margin-bottom: rem-calc(-25);
			}
		}
	}
}


@media #{$small-only} {
	.page-home {
		.contentcontainer {
			> .padded {
				padding-bottom: rem-calc(15);
			}
			> .row > .column:last-child {
				.list-unstyled:last-child > li:last-child {
					margin-bottom: 0;
					padding-bottom: 0;
				}
			}
			> .row > .column li p:last-of-type {
				margin-bottom: 0;
			}
		}
	}
}