
.logo {
	@include clearfix;
	&-white-150 { max-height: 32px; }
	img {
		float: left;
	}
	&__text {
		color: $white;
		float: left;
		font-size: rem-calc(25);
		font-weight: bold;
		line-height: 1.5;
		margin: 24px 0 0 16px;
		text-transform: uppercase;
	}
	&--top {
		img {
			margin-left: 1px;
			margin-top: 22px;
		}
		.logo__text {
			display: block;
			font-size: .8rem;
			margin-left: 10px;
			line-height: 1.4;
			max-width: 124px;
		}
	}
	&--24 {
		width: 24px;
		vertical-align: bottom;
		float: left;
		&.left {
			margin: 1px 6px 0 0;
		}
	}
}
.heading-h2 .logo--24 {
	margin-top: 2px;
}