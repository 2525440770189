.sidebar.column {
	h2 a, 
	h2 a:hover {
		color: #000;
		font-weight: 800;
	}
	section:empty { display: none; }
	p {
		padding-bottom: 20px;
	}
	img.geen {
		margin: 0;
	}
}
.emphasize {
	background-color: #f4f9f9;
	margin-bottom: $column-gutter;
	padding-right: $column-gutter;
	padding-left: $column-gutter;
	.button {
		margin: 0;
	}
	li {
		color: $primary-color;
		span {
			color: initial;
		}
		list-style: square;
	}
}

.heading-icon {
	border-bottom: $border-dark;
	position: relative;
	padding: 0 30px 0 0 0;
	&:after {
		content: url(../images/supertinywatermark_transparent.png);
		position: absolute;
		right: 0;
		bottom: 0;
	}
}