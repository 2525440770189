.pager {
	display: inline-block;
}
.yiiPager {
	@include pagination;
	.selected {
		@include pagination-current-item(true);
	}
	.hidden {
		@include pagination-unavailable-item;
		opacity: 0.5;
		pointer-events: none;
	}
}

/* Pager in list view while loading */
.list-view-loading .yiiPager {
	@include loading-indicator;
}

/* Hide those raunchy keys that come with the pagers*/
.keys {
	display: none;
}

@media#{$small-only} {
	.pager {
		li {
			display: none;
		}
		.previous, .next, .selected, .selected + li, .selected + li + li:not(.last) {
			display: inline-block;
		}
	}
}


