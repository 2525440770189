.block-content-item {
	background-size: cover;
	border-top: 18px solid #f3f2f2;
	background-repeat: no-repeat;
	background-position: center center;
	display: block;
	height: 200px;
	padding: ($column-gutter /2);
	position: relative;
	article {
		height: 100%;
		position: relative;
		width: 100%;
	}
	h1 {
		color: #000;
		bottom: 0;
		font-weight: 300;
		line-height: 1.1;
		z-index: 2;
		font-size: rem-calc(14);
		font-family: $font-family-sans-serif;
	}
	h1, .label {
		position: absolute;
	}
	.label {
		top: -33px;
		height: 18px;
		left: -($column-gutter /2);
	}
	&:before {
		transition: all 200ms ease;
		//@include background-image(linear-gradient(top, rgba($color-dark,0) 0%, rgba($color-dark,0.65) 100%));
		//opacity: 1;
	}
	&:hover {
		&:before {
			opacity: 0.5
		}
	}
	iframe {
		height: 100%;
		width: 100%;
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
	}
}
	.cssgradients .block-content-item:before {
		background-color: transparent;
	}

.list-unstyled.collapse {
	.column {
		padding: 0 $column-gutter $column-gutter 0;
	}
}

.block-slider li {
	display: none;
	&:first-child {
		display: block;
	}
}

@media#{$medium-up} {
	.block-content-item {
		h1 {
			background-color: rgba(#f3f2f2, 0.8);
			margin: -($column-gutter /2);
			padding: ($column-gutter /4);
			text-transform: none;
		}
	}
}
@media#{$small-only} {
	ul .block-content-item {
		$offset: 95px;
		background-size: 80px 60px;
		background-position: left top;
		border: none;
		height: auto;
		min-height: 60px;
		padding: 0 0 0 $offset;
		&:before {
			display: none;
		}
		.label {
			font-weight: normal;
			left: -$offset;
			min-width: ($offset - 15); 
			top: -18px;
			text-align: left;
		}
		h1 {
			color: $color-dark;
			position: relative;
			margin: 1rem 0;
		}
	}
	
	.list-unstyled.collapse {
		li.column {
			padding: 0 0 $column-gutter/2 0;
		}
	}
}