/* Slider */

.slick-slider {
	position: relative;
	display: block;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	-webkit-tap-highlight-color: transparent;
}

.slick-list {
	position: relative;
	overflow: hidden;
	display: block;
	margin: 0;
	padding: 0;
	&:focus {
		outline: none;
	}
	&.dragging {
		cursor: pointer;
		cursor: hand;
	}
}

.slick-slider .slick-track, .slick-slider .slick-list {
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.slick-track {
	position: relative;
	left: 0;
	top: 0;
	display: block;
	&:before, &:after {
		content: "";
		display: table;
	}
	&:after {
		clear: both;
	}
	.slick-loading & {
		visibility: hidden;
	}
}

.slick-slide {
	float: left;
	min-height: 1px;
	[dir="rtl"] & {
		float: right;
	}
	img {
		display: block;
	}
	&.slick-loading img {
		display: none;
	}
	display: none;
	&.dragging img {
		pointer-events: none;
	}
	.slick-initialized & {
		display: block;
	}
	.slick-loading & {
		visibility: hidden;
	}
	.slick-vertical & {
		display: block;
		height: auto;
		border: 1px solid transparent;
	}
}

.slick-slide:nth-of-type(1) {
  display: block;
}

@charset "UTF-8";
// Default Variables
$slick-loader-path: "./" !default;
$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "<" !default;
$slick-next-character: ">" !default;
$slick-dot-character: "." !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;
@function slick-image-url($url) {
	@if function-exists(image-url) {
		@return image-url($url);
	}
	@else {
		@return url($slick-loader-path + $url);
	}
}
/* Slider */
@import url(//fonts.googleapis.com/css?family=Oswald:700);

.slick {
	background-color: #fff;
	overflow: hidden;
	width: 100%;
	.caption {
		$caption-font-size: 20px;
		$caption-padding: 10px;
		font-family: 'Oswald', sans-serif;
		font-weight: bold;
		position: absolute;
		left: 20px;
		bottom: 50px;
		background-color: rgba(0,0,0,0.6);
		padding: 16px 20px 20px 20px;
		margin-right: 20px;
		z-index: 99999;
		* {
			color: #fff;
			font-size: 28px;
		}
		p {
			margin: 0;
			& + p {
				margin-top: 0.5em;
			}
		}
	}
	
	.slidernav {
		position: relative;
		display: block;
		margin-top: $column-gutter;
		
		.prev, .next {
			float: right;
			border: none;
			font-size: 3rem;
  			margin-left: 2rem;
  			transition: all 200ms;
  			cursor: pointer;
  			&:hover {
  				color: $color-home;
  			}
		}
	}
	.item-block:first-child {
		margin-top: 0;
	}
}
.slick-list {
	.slick-loading {
		background: #fff url("../images/ajax-loader.gif") center center no-repeat;
	}
}
.slick-prev, .slick-next {
	display: none !important;
}

/* Arrows */
@media #{$xlarge-up} {
	.slick-prev, .slick-next {
		background-color: transparent;
		background-repeat: no-repeat;
		background-position: center center;
		position: absolute;
		display: block !important;
		line-height: 0px;
		font-size: 0px;
		cursor: pointer;
		color: transparent;
		top: 50%;
		margin: -40px 0 0 0;
		padding: 0;
		border: none;
		outline: none;
		height: 80px;
		width: 80px;
		&:hover {
			outline: none;
			background-color: $primary-color;
			color: transparent;
		}
		&.slick-disabled:before {
			opacity: $slick-opacity-not-active;
		}
	}
	
	.slick-prev {
		background-image: url(../images/slider-left.png);
		left: 0;
	}
	
	.slick-next {
		background-image: url(../images/slider-right.png);
		right: 0;
	}
}

/* Dots */

.slick-slider {}
$dot-size: 10px;
.slick-dots {
	position: absolute;
	bottom: 25px;
	list-style: none;
	display: block;
	text-align: center;
	padding: 0;
	margin: 0;
	width: 100%;
	li {
		position: relative;
		display: inline-block;
		height: $dot-size;
		width: $dot-size;
		margin: 0 5px;
		padding: 0;
		cursor: pointer;
		button {
			border-radius: 50%;
			box-shadow: 0px 1px 5px -1px rgba(0,0,0,0.6);
			border: 0;
			background-color: rgba(255,255,255, 0.5);
			display: block;
			height: $dot-size;
			width: $dot-size;
			outline: none;
			line-height: 0px;
			font-size: 0px;
			color: transparent;
			padding: 0px;
			text-indent: -999em;
			cursor: pointer;
			&:hover, &:focus {
				outline: none;
				&:before {
					opacity: $slick-opacity-on-hover;
				}
			}
		}
		&.slick-active button {
			background-color: #fff;
		}
	}
}

@media#{$medium-up} {
	.home-header-slider {
		.slick-slide {
			height: 600px;
			.row {
				height: 600px;
				position: relative;
			}
			.column {
				position: absolute;
				bottom: 0;
				padding-right: 1.5*$column-gutter;
				
				&:nth-of-type(2) {
					right: 0;
					
					p:last-child {
						margin: 0;
					}
				}
			}
		}
	}
}

@media#{$small-only} {
	.slick {
		display: none;
	}
}