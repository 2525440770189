.writer {
	&+ .writer {
		margin-top: 2rem;
	}
	table tr {
		background: none;
		td, th {
			padding: 4px;
		}
		> *:first-child { padding-left: 0; }
		> *:last-child { padding-right: 0; }
	}
}