/* Extention van heading styling naar classes */
[class*="heading-h"] {
	color: $header-font-color;
	display: block;
	font-family: $header-font-family;
	font-weight: $header-font-weight;
	font-style: $header-font-style;
	line-height: $header-line-height;
	margin-top: $header-top-margin;
	margin-bottom: $header-bottom-margin;
}
.heading-h1 { font-size: $h1-font-size; }
.heading-h2 { font-size: $h2-font-size; }
.heading-h3 { font-size: $h3-font-size; }
.heading-h4 { font-size: $h4-font-size; }
.heading-h5 { font-size: $h5-font-size; }
.heading-h6 { font-size: $h6-font-size; }

// plaatjes uit tiny mce
.links, .rechts {
	margin-top: rem-calc(5);
	margin-bottom: rem-calc(20);
}	
.links {
	float: left;
	margin-right: rem-calc(24);
}
.rechts {
	float: right;
	margin-left: rem-calc(24);
}
.geen {
	clear: both;
	display: block;
	float: none;
	margin: 0 auto;
}

// gecentreerde inline list fixjes
.inline-list > li > * { display: initial; }
.text-center .inline-list {
	display: inline-block;
	> li:first-child { margin-left: 0; }	
}

// logo in top bar
.top-bar .name > span > a { width: auto; }