.graphcontainer {
	margin-bottom: rem-calc(20);
	* {
		font-size: 13px;
	}
	button {
		background: inherit;
		border: none;
		color: inherit;
		margin: inherit;
		font-size: inherit;
	}  
	&.google-visualization-atl .border {
		border: $border-dark;
	}
	.chartContainer {
		border-bottom: $border-dark;
	}
	.border {
		height: auto !important;
	}
	&+ .graphinfo {
		margin-top: -(1rem);
	}
}
.graphinfo {
	color: $primary-color;
	display: block;
	font-size: 0.85rem;
	margin: 0 1.5rem rem-calc(20) 0;
	padding-top: 0.3rem;
	text-align: left;
} 