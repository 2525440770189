ul {
	list-style-type: square;
}

// extra vars
$experimental-support-for-pie: false;
$add-pie-bg: false;
$border-light: 1px solid rgba(255,255,255,0.2);
$border-dark: 1px solid #ddd;

/* Imports */
@import url('//fonts.googleapis.com/css?family=Lato:300,400,700,900,300italic|Roboto:500,300italic,700italic,700,500italic,300|Roboto+Slab:300'),
	"utilities",
	"settings", 
	"foundation",
	"insyde",
	"components/index";

/* Hier de styles die voor alle platformen gelden */
html {
}
	body {
	}
	
.wrapper {
}
	main {
	}

h1 {
	margin: 4rem 0 2rem 0;
}
h2, h3, h4, h5, h6, [class*="heading-"] {
  text-transform: uppercase;
}
	* {
		> h2, h3, h4, h5, h6, [class*="heading-"] {
			&:first-child {
				margin-top: 0;
			}
		}
	}

.padded {
	padding-top: rem-calc(20);
	padding-bottom: rem-calc(20);
	& + .padded {
		padding-top: 0;
	}
	&.onderblok {
		padding-top: rem-calc(10);
	}
}
.padded-md {
	padding-top: rem-calc(20);
	padding-bottom: rem-calc(20);
	&+ .padded-md {
		border-top: 1px solid #eee;
	}
}

.linklist, .navigation {
	a {
		font-weight: 300;
	}
}

main {
	padding-bottom: 3rem;
	h1, h2, h3, h4, h5, h6, [class*="heading-h"] {
		a {
			color: $color-dark;
			&:hover {
				color: $primary-color;
			}
		}
	}
	.linklist a,
	.subnav a {
		color: #878787;
		&:hover {
			color: $primary-color;
		}
	}
}



.page_content {
	.contentcontainer {
		position: relative;
		.inner {
			position: relative;
		}
	}
}
	.contentcontainer {
		img:not(.links):not(.rechts) {
			margin: 1rem 0;
		}
	}
	.share h3 { margin-top: 2rem; }
	table {
		background-color: transparent !important;
		width: 100% !important;
		&.portfolio {
			.firstcol strong {
				display: block;
			}
		}
		thead {
			border-bottom: $border-dark;
		}
		tbody tr td {
			font-size: rem-calc(13);
		}
	}
	table thead th,
	.st-key {
		font-family: $font-family-heading;
		font-weight: bold;
	}
	.st-head-row {
		background-color: #ddebeb;
		color: #000; 
		font-family: $font-family-heading;
		text-transform: uppercase;
	}
	.small-only {
		display: none;
	}

.highlight {
	color: #e25674 !important;
}
	
//addthis
.addthis_toolbox {
	margin: 0 0 rem-calc(20px) 0;
}	

.chart {
	display: block;
	margin: 0 auto;	
}

.totop {
	margin: 1rem 0 1rem 0;
	text-align: center;
	a {
		border: 1px solid lighten($color-dark, 20%);
		border-radius: 50%;
		color: $color-dark;
		display: inline-block;
		height: 3rem;
		line-height: 3rem;
		width: 3rem;
		
		border-radius: 50%;
		transition: all 200ms;
		
		&:hover {
			color: $primary-color;
		}
	}
}

.tableannotation {
	font-size: 0.8rem;
}

/* labels */
.label {
	text-transform: uppercase;
	&.label-lg { font-size: 16px; padding: 10px; }
}
// colors
	.breakingnews .label { 
		color: #fff;
		background-color: transparent;
		width: 100%;
		left: 0 !important;
		border-bottom: 1px solid #AE1C28; 
	}
	.news .label { background-color: rgba($color-news, $hoofdgroep-label-opacity); }
	.projects .label { background-color: rgba($color-projects, $hoofdgroep-label-opacity); }
	.finance .label { background-color: rgba($color-finance, $hoofdgroep-label-opacity); }
	.business .label { background-color: rgba($color-business, $hoofdgroep-label-opacity); }
	.culture .label { background-color: rgba($color-culture, $hoofdgroep-label-opacity); }
	.aboutus .label { background-color: rgba($color-aboutus, $hoofdgroep-label-opacity); }
	.label.custom { background-color: rgba($color-home, $hoofdgroep-label-opacity); }

/* Non semantic helpers kunnen hier */
img.full-width { width: 100%; }
sup, sub { font-size: 66.75%; }
.border-top-light { border-top: $border-light; }
.border-bottom-light { border-bottom: $border-light; }
.border-top-dark { border-top: $border-dark; }
.border-bottom-dark { border-bottom: $border-dark; }
.ir {
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
}
.list-unstyled { padding: 0; margin: 0; li { list-style: none; }}

@media #{$medium-only} {
}

/* Hier alles dat alleen voor medium schermen en hoger moet gelden  */
@media #{$medium-up} {
	body {
		padding-top: 145px;
	}
	.padded {
		padding-top: rem-calc(40);
		padding-bottom: rem-calc(40);
	}
	.contentcontainer .inner {
		padding-right: rem-calc(40);	
	}
	.sidebar {
		padding-left: $column-gutter;
	}

}	

/* Hier alles dat alleen voor de hele grote schermen moet gelden */
@media #{$large-up} {
	.padded {
		padding-top: rem-calc(60);
		padding-bottom: rem-calc(60);
	}
	.padded-md {
		padding-top: rem-calc(40);
		padding-bottom: rem-calc(40);
	}
}

/* Hier alles dat alleen voor kleine schermen moet gelden */
@media#{$small-only} {
	html { //font-size: 13px;  
	}
	.large-only, #scrollingText, .tickercontainer { display: none; }
	.small-only { display: table; }
	.page_content {
		.contentcontainer:before {
			box-shadow: none;
		}
	}
}
