span[class*='flag'] {
	background-position: center center;
	background-repeat: no-repeat;
	background-size: 24px 24px;
	display: inline-block;
	height: 24px;
	vertical-align: middle;
	width: 24px;
}

.flag_nl {
	background-image: url(../images/icons/flags/nl.png);
}

.flag_en {
	background-image: url(../images/icons/flags/en.png);
}

.flag_cn {
	background-image: url(../images/icons/flags/cn.png);
}

.flag_id {
	background-image: url(../images/icons/flags/id.png);
}

@media#{$retina} {
	.flag_nl {
		background-image: url(../images/icons/flags/nl-x2.png);
	}
	.flag_en {
		background-image: url(../images/icons/flags/en-x2.png);
	}
	.flag_cn {
		background-image: url(../images/icons/flags/cn-x2.png);
	}
	.flag_id {
		background-image: url(../images/icons/flags/id-x2.png);
	}
}

.top-bar {
	transition: all 800ms;
	.top-bar-section .has-dropdown {
		&:hover > a {
			background-color: $topbar-dropdown-link-bg !important;
		}
		> a {
			padding-right: ($topbar-height / 8) !important;
			&:after {
				display: none;
			}
			&:hover {
				background-color: $topbar-dropdown-link-bg !important;
			}
		}
		.dropdown li a {
			line-height: 3rem;
			text-transform: none;
		}
		.dropdown li.active a {
			background-color: darken($topbar-dropdown-link-bg, 10%) !important;
		}
	}

	.top-bar-section .dropdown li label {
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
		cursor: default;
		padding-bottom: 0.5rem;
	}

	.left > li {
		position: relative;
		& + li {
			margin-left: 1em;
		}
		&:before {
			content: ' ';
			display: block;
			height: 1px;
			margin-left: 12px;
			position: absolute;
			top: 15px;
			width: 40px;
		}
		&:hover:before {
			display: none;
		}
		&:not(.has-form) a:not(.button):hover {
			background-color: #eeeeee;
		}
		&.home {
			&:before {
				background-color: $color-home;
			}
			&.open:not(:hover) > a, &.open > a:not(:hover) {
				color: $color-home;
			}
			&.active:not(:hover) > a, &.active > a:not(:hover) {
				color: $color-home;
			}
		}
		&:nth-child(2) {
			&:before {
				background-color: $color-news;
			}
			&.open:not(:hover) > a, &.open > a:not(:hover) {
				color: $color-news;
			}
			&.active:not(:hover) > a, &.active > a:not(:hover) {
				color: $color-news;
			}
		}
		&:nth-child(3) {
			&:before {
				background-color: $color-projects;
			}
			&.open:not(:hover) > a, &.open > a:not(:hover) {
				color: $color-projects;
			}
			&.active:not(:hover) > a, &.active > a:not(:hover) {
				color: $color-projects;
			}
		}
		&:nth-child(4) {
			&:before {
				background-color: $color-finance;
			}
			&.open:not(:hover) > a, &.open > a:not(:hover) {
				color: $color-finance;
			}
			&.active:not(:hover) > a, &.active > a:not(:hover) {
				color: $color-finance;
			}
		}
		&:nth-child(5) {
			&:before {
				background-color: $color-business;
			}
			&.open:not(:hover) > a, &.open > a:not(:hover) {
				color: $color-business;
			}
			&.active:not(:hover) > a, &.active > a:not(:hover) {
				color: $color-business;
			}
		}
		&:nth-child(6) {
			&:before {
				background-color: $color-culture;
			}
			&.open:not(:hover) > a, &.open > a:not(:hover) {
				color: $color-culture;
			}
			&.active:not(:hover) > a, &.active > a:not(:hover) {
				color: $color-culture;
			}
		}
		&:nth-child(7) {
			&:before {
				background-color: $color-aboutus;
			}
			&.open:not(:hover) > a, &.open > a:not(:hover) {
				color: $color-aboutus;
			}
			&.active:not(:hover) > a, &.active > a:not(:hover) {
				color: $color-aboutus;
			}
		}

		&.left > .open,
		&.left > :hover,
		&.left > .open {
			> a {
				color: $topbar-link-color-active;
			}
		}
	}
	.left, .nav-utility, .logo {
		transition: all 800ms;
	}

	.left {
		left: 262px;
		position: absolute;
	}
	&:not(.dense) {
		border: none;
		.left {
			left: 0px;
			position: absolute;
		}

		.logo {
			opacity: 0;
		}
	}
	&.dense {
		ul.left > li {
			// push dropdown links down to align under the colored horizontal line
			&:before {
				top: 25px;
			}
			> a {
				line-height: 2 !important;
				padding-bottom: 1rem !important;
				padding-top: 2rem !important;
			}
		}
	}
	.user-nav-horizontal {
		position: relative;
		z-index: 999;
		li,
		a:hover {
			background: none transparent !important;
			color: $primary-color !important;
		}
	}

	.top-bar-section ul li > a {
		font-family: $font-family-heading;
		font-size: 1rem;
		font-weight: 300;
	}

	.top-bar-section .list-social {
		li:not(.has-form) {
			background: transparent !important;
			margin: 0;
			padding: $topbar-link-padding;
			a:not(.button) {
				background: transparent !important;
				padding: 0;
			}
		}
	}
}

.user-nav-horizontal {
	.logged-in-as {
		a {
			color: $color-home !important;
		}
		span {
			font-size: rem-calc(14);
			padding-right: 4px;
		}
	}
}

.top-bar-container.pos-scroll {
	box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.4);
	.has-dropdown,
	.has-dropdown > a {
		max-height: 74px;
	}
}

.show-for-dense-only,
.dense .hide-for-dense {
	display: none !important;
}

.dense .show-for-dense-only {
	display: block !important;
}

.langswitch-horizontal {
	padding-right: 10px;

	ul, li, a {
		float: left;
		margin: 0;
	}
	li {
		list-style: none;
	}
	a {
		margin: 27px 0 0 10px;
	}
}

#top-pre-scroll {
	height: 70px;
	//background: #f5f5f5;
	//border-bottom: 1px solid #ccc;
	overflow: hidden;
	transition: opacity 800ms;

	.logo img {
		margin-top: 32px;
	}
}

.top-bar-section ul.nav-utility > li {
	& + li {
		position: relative;
		&:before {
			background-color: rgba(0, 0, 0, .1);
			content: ' ';
			display: block;
			height: 34px;
			margin-top: -17px;
			position: absolute;
			top: 50%;
			width: 1px;
		}
	}
	> a {
		padding: 0 10px !important;
	}
}

.dropdown-search-form {
	background-color: $color-dark;
	width: 100%;
}

// crumbs
.crumb-container {
	clear: both;
	margin-bottom: -43px;
	position: relative;
	z-index: 8;
	.hier {
		opacity: .7;
	}
}

.crumbpath ul {
	margin: 0;
	li:before {
		margin: 0 4px;
	}
}

.crumbpath *,
.crumbpath *:before {
	color: #ffffff;
}

.subnav {
	margin-bottom: -4px;

	.level1 {
		margin: 0;
		padding: 0;
	}
	.level2, .level3 {
		li {
			font-size: rem-calc(13);
			line-height: 1.2;
			margin-top: rem-calc(9);
		}
	}
	.open {
	}
	.hier > a {
		color: $color-dark;
	}
	li {
		font-size: rem-calc(14);
		list-style: none;
	}
	a {
		padding: 0.5rem 0;
	}
}

.page-1 {
	.subnav .hier > a, .sidebar .subnav h2 a, .subnav a:hover {
		color: $color-home;
	}
}

.page-2 {
	.subnav .hier > a, .sidebar .subnav h2 a, .subnav a:hover {
		color: $color-news;
	}
}

.page-3 {
	.subnav .hier > a, .sidebar .subnav h2 a, .subnav a:hover {
		color: $color-projects;
	}
}

.page-4 {
	.subnav .hier > a, .sidebar .subnav h2 a, .subnav a:hover {
		color: $color-finance;
	}
}

.page-5 {
	.subnav .hier > a, .sidebar .subnav h2 a, .subnav a:hover {
		color: $color-business;
	}
}

.page-6 {
	.subnav .hier > a, .sidebar .subnav h2 a, .subnav a:hover {
		color: $color-culture;
	}
}

.page-7 {
	.subnav .hier > a, .sidebar .subnav h2 a, .subnav a:hover {
		color: $color-aboutus;
	}
}

.linklist {
	label {
		margin-top: 0;
	}
	&.linklist-labels {
		li {
			padding-left: 20px;
			position: relative;
		}
		a {
			color: $color-dark;
			&:hover {
				color: $primary-color;
			}
		}
		.label {
			left: 0;
			padding: 3px;
			position: absolute;
			top: rem-calc(5px);
		}
	}
	.date {
		color: #878787;
		display: block;
		font-size: rem-calc(13);
		margin-top: 0.2rem;
	}
}

.inline-list > li:first-child {
	margin-left: 0;
}

.linklist,
.subnav > ul {
	> li {
		font-size: rem-calc(14);
		line-height: 1.2;
		& + li {
			margin-top: rem-calc(16);
		}
	}
}

/*
.f-topbar-fixed {
	padding-top: 0 !important;
}
*/
ul.off-canvas-list li a {
	font-weight: 400;
}

@media #{$screen} and (max-width: #{upper-bound($medium-range)}) {
	.f-topbar-fixed {
		padding-top: 0 !important;
	}
	.top-bar-container.fixed {
		position: relative;
	}
	.top-pre-scroll {
		display: none !important;
	}
	.f-dropdown.dropdown-search-form {
		top: 44px !important;
	}

	// fixed tabbar 
	.tab-bar {
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 99999;
	}
	.main-section {
		position: relative;
	}
	.main-section,
	.off-canvas-wrap,
	.off-canvas-wrap .inner-wrap {
		height: 100%;
		width: 100%;
	}
	.main-section {
		height: 100%;
		overflow-y: scroll; /* has to be scroll, not auto */
		-webkit-overflow-scrolling: touch;
	}

	.maincontainer {
		padding-top: $tabbar-height;
		top: 0;
		bottom: 0;
		position: absolute;
		left: 0;
		right: 0;
	}

}

@media#{$medium-up} {
	.contain-to-grid.fixed {
		border-bottom: 1px solid #464646;
	}
}

@media#{$large-up} {
	.top-bar .name {
		margin-right: 1rem;
	}
	.right-off-canvas-menu {
		display: none;
	}
}
